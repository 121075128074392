import React from 'react'
import { useNavigate } from 'react-router-dom'
import { login } from '../loginAndRegister/LoginAndRegister'
import { AuthContext } from '../../context/AuthContext'


const LineLogin = () => {
    const lineLoginCode = useQuery().get('code')
    const state = useQuery().get('state')
    const _redirect = JSON.parse(state)?.redirect
    const {dispatch} = React.useContext(AuthContext) 
    const initialized = React.useRef()
    const navigate = useNavigate()
    React.useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true
            handleLogin()
        }
    },[])
    function useQuery(){
      return new URLSearchParams(window.location.search)
    }
    async function handleLogin() {
        try {
            await login({method:'line',credential:lineLoginCode},dispatch)
            if(_redirect){
                console.log(_redirect)
                navigate(_redirect)
            }else{
                navigate('/user_order')
            }
        } catch (error) {
            navigate('/auth')
        }
    }
  return (
    <div>Login with Line...</div>
  )
}

export default LineLogin