import './navbar.scss'
import MenuIcon from '@mui/icons-material/Menu';
import { useContext, useState } from 'react';
import { AuthContext } from '../../context/AuthContext.js';
import { useLocation, useNavigate } from 'react-router-dom';
import HomeButton from '../homeButton/HomeBotton';

const Navbar = ({toSection}) => {
  const {user, dispatch} = useContext(AuthContext)  
  const [menuOpen,setMenuOpen] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const sections = [
    {
      label:"hm-intro",
      display:"民宿介紹"
    },
    {
      label:"hm-rooms",
      display:"各式房型"
    },
    {
      label:"hm-rules",
      display:"入住規定"
    },
    {
      label:"hm-otherinfo",
      display:"當期資訊"
    }
  ]
  const handleLogout = () => {
    dispatch(
      {
        type:'LOGOUT'
      }
    )
  }
  const handleLogin = () => {
      navigate("/auth")
  }
  return (
    <div className="navbar">
      <div className="navbar-container">
        <div className="left">
          <input
          type='button'
          className='line-btn'
          value={'LINE 客服'}
          onClick={()=>{
            window.location.assign(
              'https://line.me/ti/p/0na8LypOV2'
              )
          }}
          />
        </div>
        <div className="middle">
          <HomeButton
          onClick={()=>{
            setMenuOpen(false)
            if(location.pathname!=='/'){
              navigate("/")
            }else{
              toSection('hm-cover')
            }
          }}
          />
        </div>
        <div className="right">
          <button 
          className="menu-btn"
          onClick={()=>setMenuOpen(!menuOpen)}
          >
            <MenuIcon 
            className={`menu-icon ${menuOpen&&"open"}`} 
            fontSize="small" 
            />
          </button>        
        </div>
      </div>
      {
        <div 
        className={`menu-items ${menuOpen&&"open"}`}
        onBlur={()=>setMenuOpen(false)}
        >
          {
            (!user)
            ?<div 
            className="menu-item login-btn" 
            onClick={()=>handleLogin()}>
              登入|註冊
              </div>
            :
            <>
            <div 
            className="menu-item login-btn"
            onClick={()=>{
            navigate(`/user_order`)
            setMenuOpen(false)
            }}>
              我的訂單
            </div>
            <div 
            className="menu-item" 
            onClick={()=>{
              navigate(`/user`)
              setMenuOpen(false)
              }}>
              個人資訊
            </div>
            </>
          }
          {
          sections.map(({label,display})=>
            <div 
            className="menu-item" 
            key={label}
            onClick={()=>{
              if(location.pathname!=='/'){
                navigate("/",{state:{'toSection':label}})
              }else{
                setMenuOpen(!menuOpen)
                toSection(label)
              }
            }}
            >
              {display}
              </div>
          )}
          <div 
          className="menu-item"
          onClick={()=>{
            document.location.assign('https://sites.google.com/view/fong-pension-info/豐-台東景點推薦')
          }}
          >
            旅遊推薦
          </div>
          {
            (user)
            &&<div 
            className="menu-item logout-btn" 
            onClick={()=>handleLogout()}
            >
              立即登出
              </div>
          }
      </div>
      }
      <div className="navbar-bottom-shadow"/>
      {
        menuOpen&&
        <div 
          className="menu-disable-area"
          onClick={()=>setMenuOpen(false)}
        />
      }
    </div>
  )
}
export default Navbar