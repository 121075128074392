import React from "react"
const ProgressiveImage = ({placeholderSrc,src,...props}) => {
  const [imgSrc,setImgSrc] = React.useState(placeholderSrc||src)
  const srcRef = React.useRef()
  const [hidden,setHidden] = React.useState(true)
  function isCached (url) {
    const imgEle = new Image()
    imgEle.src = url
    imgEle.onload = () => {
      if(url===placeholderSrc){
        setHidden(false)
      }
    }
  }
  React.useEffect(()=>{
    isCached(placeholderSrc)
    srcRef.current = new Image()
    srcRef.current.src = src
    if(!srcRef.current.complete){
      setImgSrc(placeholderSrc)
      srcRef.current.onload = () => {
        if(srcRef.current.src === src){
        setImgSrc(src)
        }
      }
    }else{
      setImgSrc(src)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[src])
    return(
      <img
      {...{'src':imgSrc,...props}}
      alt={props?.alt||''}
      hidden={hidden}
      />
    )
  }
export default ProgressiveImage