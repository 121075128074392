import axios from 'axios';
import {useState,useEffect} from 'react'

const useFetch = (url,req) => {
    const [data,setData]=useState([]);
    const [loading,setLoading]=useState(false);
    const [error,setError]=useState(false);
    useEffect(()=>{
        const fetchData = async () => {
            setLoading(true);
            try {
                const res = await axios.get(url,req);
                setData(res.data);
                // setData(fakeData[url])
            } catch (error) {
                setError(error);
            }
            setLoading(false);
        };
        if(url){
            fetchData();
        }
    },[url]);
    const refetch = async () => {
        setLoading(true);
        try {
            const res = await axios.get(url,req);
            setData(res.data);
            // setData(fakeData[url])
        } catch (error) {
            setError(error);
        }
        setLoading(false)
    };
    return {data,loading,error,refetch};
}
export default useFetch;