import axios from "axios";
import { useContext } from "react";
import { useState } from "react";
import { createContext, useReducer } from "react"
import { AuthContext } from "./AuthContext";

const INITIAL = {
    'order':[],
    'waitingOrder':[],
    'completedOrder':[],
    'discount':[],
    'userInfo':null,
}
export const UserContext = createContext(INITIAL)
const UserReducer = (state,action) => {
    switch(action.type){
        case 'SET_FETCHED_DATA':
            return {
                ...state,
                ...action.payload
            }
        case 'SET_USERINFO':
         return {
            ...state,
            ...action.payload
         }
        default: break;
    }
}
export const UserContextProvider = ({children}) => {
    axios.defaults.withCredentials = true
    const {user} = useContext(AuthContext)
    const [initialized,setInitialized] = useState(false)
    const [state,dispatch] = useReducer(UserReducer,INITIAL)
    const initialize = () => {
        if(!initialized){
            fetchData()
            setInitialized(true)
        }
    }
    const fetchData = async () => {
        try {
            let userInfo,
                order,
                // waitingOrder,
                completedOrder,
                discount
            //fetch user
            userInfo = user?.userId
                        ? await axios.get(
                            process.env.REACT_APP_API_ADDRESS + `/api/user/${user.userId}`,
                            { withCredentials: true }
                            ).then((res)=>res.data[0])
                        : null
            discount = (userInfo?.discountIds.length>0)
                        ? await axios.get(
                            process.env.REACT_APP_API_ADDRESS + `/api/info/discount_by_discountids/`,
                            {params:{'discountids':userInfo.discountIds}}
                            ).then((res)=>res.data)
                        : []    
            order = (userInfo?.order.length>0||userInfo?.completedOrder.length>0)
                        ? await axios.get(
                            process.env.REACT_APP_API_ADDRESS +'/api/order/?',
                            {params:{'orders':
                            [
                                ...userInfo.order,
                                userInfo.completedOrder[0],
                                userInfo.completedOrder[1],
                                userInfo.completedOrder[2],
                            ].flat()
                            }}
                            ).then((res)=>res.data)
                        : [] 
            // waitingOrder = (userInfo?.waitingOrder.length>0)
            //             ? await axios.get(
            //                 process.env.REACT_APP_API_ADDRESS + '/api/order/?',
            //                 {params:{'orders':userInfo.waitingOrder}}
            //                 ).then((res)=>res.data)
            //             : []    
            // completedOrder = (userInfo?.completedOrder.length>0)
            //                 ? await axios.get(
            //                 process.env.REACT_APP_API_ADDRESS + '/api/order/completed/?',
            //                 {params:{'orders':userInfo.completedOrder}}
            //                 ).then((res)=>res.data)
            //                 : []      
            //dispatch
            dispatch({
                type:'SET_FETCHED_DATA',
                payload:{
                        'userInfo': user
                                    ?{...userInfo,'userId':user.userId}
                                    :null,
                        order,
                        // waitingOrder,
                        // completedOrder,
                        discount
                    }
                })
        } catch (error) {
            console.log(error)
        }
    }
    const hasOrder = (orderId) => {
        for(const type of ['order','waitingOrder','completedOrder']){
            if(state[type].find(({_id})=>_id===orderId)){
                return true
            }
        }
        return false
    }
    //initialize
    initialize()
    return(
        <UserContext.Provider
            value={
                {
                    ...state,
                    dispatch,
                    fetchData,
                    hasOrder
                }
            }
        >
            {children}
        </UserContext.Provider>
    )
}