import { Route, createBrowserRouter, RouterProvider, createRoutesFromElements, redirect  } from "react-router-dom";
import { OrderContextProvider } from "./context/OrderContext.js";
import Home from "./pages/home/Home.jsx";
import Auth from "./pages/auth/Auth.jsx";
import Reserve from "./pages/reserve/Reserve.jsx";
import User from "./pages/user/User.jsx";
import { UserContextProvider } from "./context/UserContext.js";
// import { useContext } from "react";
// import { AuthContext, AuthContextProvider } from "./context/AuthContext.js";
import {checkInAppBrowser} from './function/checkInAppBrowser.js'
import LineLogin from "./components/lineLogin/LineLogin.jsx";
import { useContext } from "react";
import { AuthContext } from "./context/AuthContext.js";
import Order from "./pages/order/Order.jsx";

function App() {
  const {user} = useContext(AuthContext)
  const loader_authCheck = () => {
    if(!user){
      return redirect('/auth')
    }else{
      return true
    }
  }
  const router = createBrowserRouter(
    createRoutesFromElements(
    <Route>
    <Route path="/" loader={checkInAppBrowser}>
      <Route index element={<Home/>}/>
      <Route 
      path="auth"
      element={<Auth/>}
      />
      <Route 
      path="auth/line"
      element={<LineLogin/>}
      />
      <Route path="reserve" element={
          <OrderContextProvider>
            <Reserve/>
          </OrderContextProvider>
      }/>
      <Route path="user"
      element={
        <UserContextProvider>
          <User/>
        </UserContextProvider>
      }/>
      <Route path="user_order"
      element={
        <UserContextProvider>
          <User onlyOrder={true}/>
        </UserContextProvider>
      }/>
      <Route path="order/:id" 
      element={
        // <UserContextProvider>
          <Order/>
        // </UserContextProvider>
      }/>
      <Route path='*' loader={()=>redirect('/')}/>
    </Route>
   </Route>
    )
  )
  return (
      <RouterProvider router={router}/> 
   );
}

export default App;
