import './reserve.scss';
import {useContext, useEffect, useState} from 'react'
import ChooseDateAndRoom from '../../components/chooseDateAndRoom/ChooseDateAndRoom';
import OrderForm from '../../components/orderForm/OrderForm';
import CheckForm from '../../components/checkForm/CheckForm';
import { AuthContext } from '../../context/AuthContext';
import { OrderContext } from '../../context/OrderContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import LoginAndRegister from '../../components/loginAndRegister/LoginAndRegister';
import HomeButton from '../../components/homeButton/HomeBotton';

const Reserve = () => {
    axios.defaults.withCredentials = true
    const navigate = useNavigate()
    const {
      'dispatch':authDispatch
    } = useContext(AuthContext)
    const {
      order,
      'userData':user,
      'dispatch':orderDispatch,
      isInitialized
    } = useContext(OrderContext)
    //session
    const [tabName,setTabName] = useState([
      '選擇日期及房型',
      '填寫訂單',
      '確認訂單'

    ])
    // const [sessions, setSessions] = useState(
    //    [
    //     {
    //       session:0,
    //       name:'choose-date-room',
    //       alias:'選擇日期及房型',
    //       isFullfilled:false,
    //       nextSession:1
    //     },
    //     {
    //       session:1,
    //       name:'fill-out-details',
    //       alias:'填寫訂單',
    //       isFullfilled:false,
    //       nextSession:2
    //     },
    //     {
    //       session:2,
    //       name:'check-details',
    //       alias:'確認訂單',
    //       isFullfilled:false,
    //       nextSession:null
    //     },
    //     ]
    // )
    // const [sessions, setSessions] = useState(
    //    [
    //     {
    //       session:0,
    //       name:'choose-date-room',
    //       alias:'選擇日期及房型',
    //       isFullfilled:false,
    //       nextSession:1
    //     },
    //     {
    //       session:1,
    //       name:'login-or-register',
    //       alias:'登入或註冊',
    //       isFullfilled:false,
    //       nextSession:2
    //     },
    //     {
    //       session:2,
    //       name:'fill-out-details',
    //       alias:'填寫訂單',
    //       isFullfilled:false,
    //       nextSession:3
    //     },
    //     {
    //       session:3,
    //       name:'check-details',
    //       alias:'確認訂單',
    //       isFullfilled:false,
    //       nextSession:null
    //     },
    //     ]
    // )
    // const [curSession,setCurSession]= useState(0)
    const [tab,setTab]= useState(0)
    const [orderCreating,setOrderCreating] = useState(false)
    const [error,setError] = useState(false)
    // const makeSessionFullfilled = (sessions,sessNum,isFullfilled) => {
    //   let newSess = [...sessions]
    //   newSess.find(({session})=>session===sessNum).isFullfilled = isFullfilled
    //   return  newSess
    // }
    // const findSessionIndex = (sessNum) => {
    //   return sessions.findIndex((i)=>i.session===sessNum)
    // }

    async function createOrder (userinfo) {
      try {
        const comparedFields = [
          'night',
          'roomPerson',
          'totalPerson',
          'roomPrice',
          'addPersonPrice',
          'discountPrice',
          'totalPrice',
          'requiredDepositAmount'
        ]
        const request = document.getElementById('textarea-other-request')?.value
        const otherRequest = request?
                              [{request}]:
                              []
        const req = {
          "startDate":order.startDate,
          "endDate":order.endDate,
          "orderType":order.orderType,
          "roomNumber":order.roomNumber,
          "addPersonRoomNumber":order.addPersonRoomNumber,
          "discountId":order.discountId,
          "orderer":userinfo.orderer,
          "mobile":userinfo.mobile,
          "address":userinfo.address,
          "email":userinfo.email,
          "veganNumber":order.veganNumber,
          "veganType":order.veganType,
          "otherRequest":otherRequest,
          'isDraft':true,
          'isTesting':false
        }
        const res = await axios.post(
          process.env.REACT_APP_API_ADDRESS + `/api/order/new`,
          req
          )
        //confirm data
        for(const field of comparedFields){
          const err = res.data[field] !== order[field]
                      ?new Error(`${field}:${res.data[field]}!==${order[field]}`)
                      :null
          if(err){
            console.log(err)
            throw err
          }
        }
        //has confirmed and send confirm
        await axios.put(
          process.env.REACT_APP_API_ADDRESS + `/api/order/${res.data._id}/confirm`
          )
        //navigate to user page and open the order
        navigate('/user_order',{state:{openedOrderId:res.data._id}})
      } catch (error) {
        const errorMessage = (error.response.data.message.split(' '))[0]
        const errors = {
          'duplicated':'您當日已有訂單，請先取消之前訂單'
        }
        orderDispatch({
          'type':'RESET_ORDER'
        })
        setError(errors?.[errorMessage]||`訂單無法成立，請重新下單`)
        throw error
      }
    }
    const handleCreateOrder = async (userinfo) => {
      try {
        setOrderCreating(true)
        if(!orderCreating){
          await createOrder(userinfo)
        }
      } catch (error) {
        setOrderCreating(false)
        setTab(0)
        orderDispatch({
          'type':'RESET_ORDER',
          'payload':{
            'orderer':user?.username,
            'mobile':user?.mobile,
            'address':user?.address,
          }
        })
      }
    }
    //next ntn
    // const btnText = function(){
    //   const allBtnText = [
    //     {
    //       'session':0,
    //       'getText':()=>{
    //         switch(order.orderType){
    //           case 'availible':
    //             return '可以預訂，下一步'
    //           case 'waiting':
    //             return '僅能候補，下一步'
    //           default:
    //             return '請選擇日期／房型'
    //         }
    //       }
    //     },
    //     {
    //       'session':1,
    //       'getText':()=>{
    //           return '完成，下一步'
    //       }
    //     },
    //     {
    //       'session':2,
    //       'getText':()=>{
    //         if(sessions.find(({session})=>session===2).isFullfilled){
    //           return '完成，送出訂單'
    //         }else{
    //           return '請確認資訊'
    //         }
    //       }
    //     }
    //   ]
    //   return allBtnText.find(({session})=>session===curSession).getText()
    // }()
    // async function handleNextBtnClick(e){
    //   e.preventDefault()
    //   const firstSession = 0
    //   const lastSession = sessions.length-1
    //   switch(e.target.id){
    //       case 'next': 
    //       if(!user){
    //         // localStorage.setItem('order',JSON.stringify(order))
    //         // localStorage.setItem('redirect','/reserve')
    //         navigate('/auth',{state:{goto:`/reserve/?startDate=${order.startDate.toDateString()}&endDate=${order.endDate.toDateString()}&${order.roomNumber.map(i=>'roomNumber[]='+i).join('&')}`}})
    //       }
    //       // else{
    //       //   localStorage.removeItem('order')
    //       // }
    //       if(curSession===lastSession){
    //         try {
    //           setOrderCreating(true)
    //           if(!orderCreating){
    //             await createOrder()
    //           }
    //         } catch (error) {
    //           let newSess = [...sessions]
    //           //set isFullfilled to false
    //           for(let sess of newSess){
    //             const sessNum = sess.session
    //             let isFullfilled = false
    //             if(
    //               user && 
    //               sessNum===1
    //                ){
    //                 isFullfilled = true
    //                }
    //             newSess = makeSessionFullfilled(
    //                                             newSess,
    //                                             sessNum,
    //                                             isFullfilled
    //                                             )
    //           }
    //           setOrderCreating(false)
    //           setSessions(newSess)
    //           setCurSession(0)
    //           orderDispatch({
    //             'type':'RESET_ORDER',
    //             'payload':{
    //               'orderer':user?.username,
    //               'mobile':user?.mobile,
    //               'address':user?.address,
    //             }
    //           })
    //         }
    //         break
    //       }else{
    //         let newSessNum = curSession+1
    //         setCurSession(newSessNum)
    //         break
    //       }
    //       case 'previous': 
    //       if(curSession===firstSession){
    //         break
    //       }else{
    //         let newSessNum = curSession-1
    //         // if(
    //         //   newSessNum===1
    //         //   &&sessions[findSessionIndex(1)].isFullfilled
    //         //   ){
    //         //   newSessNum = newSessNum-1
    //         // }
    //         setCurSession(newSessNum)
    //         break;
    //       }
    //       default:break;
    //     }
    // }
    // const NextButtons = () => {
    //   return(
    //     <div className="next-button-container">
    //             <div className='next-button'>
    //               <button 
    //                 className={`next-session`}
    //                 disabled={!(sessions[tab].isFullfilled)}
    //                 id='next'
    //                 onClick={(e)=>handleNextBtnClick(e)}
    //               >
    //                 {btnText}
    //               </button>
    //               {
    //                 curSession!==0&&
    //                 <button
    //                   className={`previous-session`}
    //                   disabled={(curSession===0)}
    //                   id='previous'
    //                   onClick={(e)=>handleNextBtnClick(e)}
    //                 >
    //                   上一步
    //                 </button>
    //               }
    //             </div>
    //     </div>
    //   )
    // }
    //check user
    useEffect(()=>{
      // const checkLogin = () => {
      //   let newSessions = [...sessions]
      //   let target = newSessions[findSessionIndex(1)]
      //   if(
      //     user && 
      //     !target.isFullfilled
      //     ){
      //       target.isFullfilled = true
      //       setSessions(newSessions)
      //   }else if(
      //     !user && 
      //     target.isFullfilled
      //     ){
      //       target.isFullfilled = false
      //       setSessions(newSessions)
      //     }
      //   if(
      //     !user && 
      //     ![0,1].includes(curSession)
      //     ){
      //       setCurSession(
      //         sessions
      //         .find(({session,isFullfilled})=>
      //         [0,1].includes(session)
      //         &&isFullfilled
      //         ).session
      //         )
      //     }
      // }
      const checkLogin = () => {
        if(
          !user && 
          tab!==0
          ){
            setTab(0)
          }
      }
      checkLogin()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[user])
  return (
      isInitialized&&
      <div className="reserve">
          {error&&
            <div className="order-error">
              <div className="order-error-msg">{`錯誤：${error}`}</div>
              <button 
              className="order-error-btn"
              onClick={()=>setError(null)}
              >了解</button>
            </div>
          }
          <div className="reserve-top">
              <div className="top-container">
                  <div className="logo">
                    <HomeButton/>
                  </div>
                  <div className="session-title">
                      {tabName[tab]}
                  </div>
                  <div className="right">
                    <div 
                    className="right-container" 
                    onClick={()=>
                      {
                      if(!user){
                        navigate('/auth')
                      }else{
                        authDispatch({type:'LOGOUT'})
                      }
                      }}
                    >
                      {
                          [user].map((i)=>{
                            const text = i?'登出':'登入'
                            return text.split('').map((w,index)=>
                            <span
                             key={index}
                            >
                              {`${w}`}
                              </span>
                            )
                          })
                      }
                    </div>
                    </div>
              </div>
          </div>
            <div className="reserve-middle">
              <div 
              // className={`session-item ${(curSession!==0)&&"closed"}`}
              className={`session-item ${(tab!==0)&&"closed"}`}
              >
                <div className="session-item-top scroll">
                  <ChooseDateAndRoom 
                  // setSessions={setSessions}
                  setTab={setTab}
                  />
                </div>
                {/* <NextButtons/> */}
              </div>
              <div 
              // className={`session-item ${(curSession!==1)&&"closed"}`}
              className={`session-item ${(tab!==1)&&"closed"}`}
              >
                <div className="session-item-top">
                      <OrderForm
                      // enable={curSession===1}
                      setTab={setTab}
                      />
                </div>
                    {/* <NextButtons/> */}
              </div>
              <div 
              // className={`session-item ${(curSession!==2)&&"closed"}`}
              className={`session-item ${(tab!==2)&&"closed"}`}
              >
                  <div className="session-item-top">
                      <CheckForm
                      // enable={curSession===2}
                      // setSessions={setSessions}
                      setTab={setTab}
                      handleCreateOrder={(userinfo)=>handleCreateOrder(userinfo)}
                      />
                  </div>
                    {/* <NextButtons/> */}
              </div>
            </div>
      </div>
  )
}
export default Reserve