
const LineAuth = ({callbackUri,state,...props}) => {
    const handleClick = () => {
        let link = 'https://access.line.me/oauth2/v2.1/authorize?';
        link += 'response_type=code';
        link += '&client_id=' + process.env.REACT_APP_LINE_CLIENT_ID;
        link += '&redirect_uri=' + encodeURIComponent(callbackUri);
        link += '&state='+encodeURIComponent(JSON.stringify(state));
        // link += '&state=login';
        link += '&scope=profile%20openid%20email';
        window.location.href = link;
    }
return (
    <div 
    {...props}
    onClick={()=>handleClick()}
    style={{
        textAlign:'center',
        fontSize:'14px',
        backgroundColor:'#06C755',
        padding:'10px'
    }}
    >
        使用 LINE 帳號登入
    </div>
)
}
export default LineAuth