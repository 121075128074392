import './calendar.scss'
import { format } from 'date-fns'
import { zhTW } from 'date-fns/locale';
import { formatCurrencyText } from '../../function/formTools';

const Calendar = ({calendar,setOpenRoomNumber}) => {
  const isloading = calendar.length===0  
  const dateList = grpByYrMn(calendar)
  function grpByYrMn (list) {
    let newList = {
      'title':[],
      'group':[]
    }
    for(const item of list){
      const yrMn = format(
        new Date(new Date(item.date).toLocaleString('en-US',{timeZone:'Asia/Taipei'}))
        ,'yyyy/MM')
      const index = newList.title.findIndex(i=>i===yrMn)
      if(index>-1){
        newList.group[index].push(item)
      }else{
        newList.title.push(yrMn)
        newList.group.push([item])
      }
    }
    return newList
  }
  return (
    <div 
    className="calendar"
    >
      {
      !isloading&&
      <>
        <div className="choose-month">
              跳至
                <select 
                name="dateListYrMn" 
                id="dateListYrMn"
                onChange={(e)=>{
                  e.preventDefault()
                  document.getElementById(e.target.value)?.scrollIntoView({'behavior':'smooth'})
                }}>
                  {
                  dateList.title.map((item)=>{
                    return(
                    <option 
                      value={item} 
                      key={item}
                      >
                        {item}
                      </option>
                    )
                  }
                  )}
                </select>
          </div>
        <div className="calendar-container"> 
          {
          dateList.group.map((group,index)=>{
            const yrMn = dateList.title[index]
            const datesNoClosed = group.filter(item=>item.dateType!=='closed')
            return(
              <div 
              className="month" 
              id={yrMn}
              key={yrMn} 
              >
                <div className="month-display">
                  {
                  format(
                    new Date(new Date(yrMn+'/01').toLocaleString('en-US',{timeZone:'Asia/Taipei'}))
                    ,'yyyy/MMM',{locale:zhTW})
                  }
                  </div>
                {
                datesNoClosed.map((item,index)=>{
                  const dateType = {
                    'weekday':'平日',
                    'weekend':'周末'
                  }?.[item.dateType]||item.dateType
                  const isNormalDay = ['weekday','weekend'].includes(item.dateType)
                  const formateDate = new Date(new Date(item.date).toLocaleString('en-US',{timeZone:'Asia/Taipei'}))
                  const date = format(new Date(formateDate),'MM/dd')
                  const weekday = format(new Date(formateDate),'iiii',{locale:zhTW})
                  const lastItem = ((datesNoClosed.length-1)===index)
                  // function roomItemMkr (rooms,date){
                  //   return(
                  //     rooms.map((room)=>{
                  //       const roomNumber = room.roomNumber
                  //       const roomStatus = () => {
                  //         if(room.isClosed){
                  //           return 'closedRoom'
                  //         }else if(room.hasOrder){
                  //           return 'waitingRoom'
                  //         }else{
                  //           return 'availibleRoom'
                  //         }
                  //       }
                  //       const roomPrice = room.roomPrice
                  //       return(
                  //       <div className={`room-item ${roomStatus()}`} key={`${date}-${roomNumber}`}>
                  //         <div className="room-number">{roomNumber}</div>
                  //         <div className="room-price">{`${roomPrice}元`}</div>
                  //       </div>
                  //         )
                  //     })
                  //   )
                  // }
                  return(
                    <div 
                      className={`item ${ lastItem && 'last' }`} 
                      key={item.date}
                    >
                      <div className='item-left'>
                        <div className="date-info">
                          <span>{date}</span>
                          <span>{weekday}</span>
                        </div>
                        <span className={`date-type ${isNormalDay && 'normal'}`}>{dateType}</span>
                      </div>
                      <div className="item-right">
                        {
                          item.room.map((room)=>{
                            const {
                              roomNumber,
                              price
                            } = room
                            const roomStatus = function(){
                              if(room.isClosed){
                                return 'closed'
                              }else if(room.hasOrder){
                                return 'waiting'
                              }else{
                                return 'availible'
                              }
                            }()
                            return(
                                roomStatus!=='closed'&&
                                <div 
                                  className={`room-item ${roomStatus}`} 
                                  key={`${date}-${roomNumber}`}
                                  onClick={()=>setOpenRoomNumber(roomNumber)}
                                >
                                  <div className="room-number">{`${roomNumber}${roomStatus==='waiting'?' 已訂':''}`}</div>
                                  <div className="room-price">{`${formatCurrencyText(price)}元`}</div>
                                </div>
                              
                              )
                          })
                        // roomItemMkr(item.room,date)
                        }
                      </div>
                    </div>
                    )
                  })
                }
              </div>
            )
            })
          }
          </div>
      </>
      }
    </div>
  )
}

export default Calendar