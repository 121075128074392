import {useLocation, useNavigate } from 'react-router-dom'
import Footer from '../../components/footer/Footer'
import Navbar from '../../components/navbar/Navbar'
import './home.scss'
import { useEffect, useState } from 'react'
import axios from 'axios'
import Information from '../../components/information/Information'
import ItemList from '../../components/itemList/ItemList'
import RoomView from '../../components/roomView/RoomView'
import ProgressiveImage from '../../components/progressiveImage/ProgresiveImage'

const Home = () => {
  const [info, setInfo] = useState()
  const [coverIndex, setCoverIndex] = useState(0)
  const [openRoomIndex,setOpenRoomIndex] = useState(null)
  const [photoLoading,setPhotoLoading] = useState({
    cover:true,
    room:false,
    activity:false
  })
  const location = useLocation()
  const navigate = useNavigate()
  const cacheImages = (srcArray) => Promise.all(srcArray.map((src)=>
    new Promise((resolve,reject)=>{
      const img = new Image()
      img.src = src
      img.onload = ()=>resolve()
      img.onerror = (err)=>reject(err)
    })
  ))
  const getHomePageInfo = async () => {
    const res = await axios.get(
      process.env.REACT_APP_API_ADDRESS + '/api/info/home_page_data'
      ).then((v)=>v.data)
    setInfo({
      ...res,
      // 'isBookingPaused':true,
      'rooms':res.rooms.sort((a,b)=>a.roomNumber-b.roomNumber)
    })
    cacheImages(
      res.photo.map(({medium})=>medium.url)
      ).then(()=>setPhotoLoading(prev=>({...prev,room:true,activity:true})))
  }
  const toSection = (section) => {
      const element = document.getElementById(`${section}`)
      const yOffset = -90
      if(element){
        const y = element.getBoundingClientRect().top 
                  + window.scrollY
                  + yOffset
        window.scrollTo({top:y,behavior:'smooth'})
      }
  }
  useEffect(()=>{
      if(!info){
        getHomePageInfo()
      }
      const section = location.state?.toSection
      if(section&&info){
        toSection(section)
        window.history.replaceState(null, '')
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
  },[info])
  // console.log(info?.photo?.[coverIndex]?.thumb?.url)
  return (
    <div className="home">
          <div className="home-layer-1">
            <Navbar
            toSection={toSection}
            />
            <div className="home-bottom">
              <div className="home-container">
                {/* cover */}
                  <div 
                    className="hm-cover"
                    id='hm-cover'
                  >
                    <div className="hm-button-container">
                      {
                        info&&
                        (
                          !info?.isBookingPaused?
                        <button 
                        onClick={()=>navigate('/reserve')}
                        >
                          立即預定
                        </button>:
                        <button 
                        disabled
                      >
                        暫停預定
                      </button>
                      )
                      }
                    </div>
                    {
                    info?.photo&&
                      <ProgressiveImage
                        src={info.photo[coverIndex].url}
                        placeholderSrc={info.photo[coverIndex].medium.url}
                      />
                    }
                  </div>
                {/* photo */}
                <div 
                className="hm-section photo"
                >
                  <ItemList>
                    {
                      info?.photo.map((i,index)=>{
                            return(
                            <ProgressiveImage
                            placeholderSrc={i.thumb.url}
                            key={index}
                            onClick={()=>{
                              setCoverIndex(index)
                            }}
                            />
                            )
                          }
                      )
                    }
                  </ItemList>
                </div>  
                {/* intro */}
                <div 
                  className="hm-section"
                  id='hm-intro'
                >
                  <div className="hm-sec-title">民宿介紹</div>
                  <div className="hm-sec-container">
                      <Information
                      info={info?.intro||''}
                      />
                    </div> 
                </div>
                {/* rooms */}
                <div 
                className="hm-section"
                id='hm-rooms'
                >
                  <div className="hm-sec-title">各式房型</div>
                    <ItemList>
                      {
                        info?.rooms
                        .map((room,index)=>{
                          return(
                            <div 
                            className="hm-room-item"
                            id={room.roomNumber}
                            key={index}
                            onClick={()=>{
                              setOpenRoomIndex(index)
                              toSection(room.roomNumber)
                            }}
                            >
                              {photoLoading.room&&
                              <ProgressiveImage
                              // src={room.photo[0]?.medium.url}
                              placeholderSrc={room.photo[0]?.thumb?.url}
                              />
                              } 
                              <div className="hm-room-i-title">
                                <div className='hm-room-i-roomNumber'>{`${room.roomNumber}`}</div> 
                                <span>{`${room.person}人房`}</span>
                              </div>
                            </div>
                          )
                        })
                      }
                    </ItemList>
                </div>
                {/* rules */}
                <div 
                className="hm-section"
                id='hm-rules'
                >
                  <div className="hm-sec-title">入住規定</div>
                  <div className="hm-sec-container hm-rules">
                    {
                      info?.rule.map((rule,index)=>{
                        return(
                          <div
                            key={index}
                          >
                            <Information
                              info={rule}
                            />
                          </div>
                        )
                      })
                    }
                    </div>
                </div>
                {/* otherInfo */}
                <div 
                className="hm-section"
                id={
                  info?.otherInfo.length>0
                  ?'hm-otherinfo'
                  :''
                }
                >
                {
                  info?.otherInfo.length>0&&
                  <>
                  <div className="hm-sec-title">當期資訊</div>
                    <ItemList>
                      {
                        info.otherInfo
                        .map((post,index)=>{
                          return(
                            <div 
                            className="hm-post-item"
                            key={post._id}
                            onClick={()=>{
                              if(post.url){
                              window.location.assign(post.url)
                              }
                            }}
                            >
                              {photoLoading.activity&&
                              <ProgressiveImage
                              src={post?.img}
                              placeholderSrc={info?.photo?.[0]?.thumb.url}
                              />
                              } 
                              <div className="hm-post-title">
                                {post.title}
                              </div>
                            </div>
                          )
                        })
                      }
                    </ItemList>
                  </>
                }
                </div>
                {/* map */}
                <iframe 
                title='map'
                className='hm-map'
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3679.5211581646827!2d121.10100167517986!3d22.746032679368632!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x346fb8346e951b71%3A0x8708bc27752a3603!2z6LGQIFBFTlNJT07vvIhGT05HIFBFTlNJT07vvInjgJDms6jmhI_vvJrlt7LnhKHkvpvmh4nkuIvljYjojLbvvI_nhKHoiIcgQWdvZGEg5ZCI5L2c44CR!5e0!3m2!1sen!2stw!4v1689668758552!5m2!1sen!2stw" 
                allowFullScreen
                loading="lazy" 
                referrerPolicy="no-referrer-when-downgrade">
                </iframe>
                <iframe 
                title='facebook'
                className='hm-facebook'
                src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fprofile.php%3Fid%3D100063944147100&tabs=timeline&width=500&height=70&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" 
                allowFullScreen
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                >
                </iframe>
              </div>
              <Footer/>
            </div>
          </div>
          {
            info&&
            openRoomIndex!==null&&
            <div className="home-layer-2">
              <RoomView
              room={info.rooms[openRoomIndex]}
              goTo={(input)=>{
                const count = info.rooms.length
                setOpenRoomIndex((prev)=>{
                  return(input
                        ?((count+prev+input)%count)
                        :null)
                })
              }}
              reserve={
                info?.isBookingPaused
                ?null
                :()=>navigate('/reserve')
              }
              close={()=>setOpenRoomIndex(null)}
              />
            </div>
          }
    </div>
  )
}
export default Home