import "./roomView.scss"
import { useState } from "react"
import Information from "../information/Information"
import ProgressiveImage from "../progressiveImage/ProgresiveImage"

const RoomView = ({room,goTo,reserve,close}) => {
    const {
        name,
        roomNumber,
        person,
        desc,
        feature,
        photo
    } = room
    const [mainPhotoIndex,setMainPhotoIndex] = useState(0)
    return(
        <div className="roomView">
            <div 
            className="rv-bk"
            onClick={()=>close()}
            />
            <div className="rv-frame">
                <div className="rv-container">
                    <div className="rv-top">
                        {
                            goTo&&
                            <div 
                            className="rv-btn"
                            onClick={()=>goTo(-1)}
                            >
                                ❮
                            </div>
                        }
                        
                        <div className="rv-title">
                            {`【${roomNumber}】 ${person}人房-${name}`}
                        </div>
                        {
                            goTo&&
                            <div 
                            className="rv-btn"
                            onClick={()=>goTo(1)}
                            >
                                ❯
                            </div>
                        }
                    </div>
                    <div className="rv-main-photo">
                                <ProgressiveImage
                                    src={photo[mainPhotoIndex]?.url}
                                    placeholderSrc={photo[mainPhotoIndex]?.medium?.url}
                                />
                    </div>
                    <div className="rv-photo">
                        {
                            photo.map((value,index)=>{
                                return(
                                <ProgressiveImage
                                    src={value?.medium?.url}
                                    placeholderSrc={value?.medium?.url}
                                    key={index}
                                    onClick={()=>setMainPhotoIndex(index)}
                                />
                            )}
                            )
                        }
                        
                    </div>
                    <div className="rv-section">
                        <Information
                            info={desc}
                        />
                    </div>
                    <div className="rv-section wrap">
                            {
                                feature.map((value,index)=>
                                <div 
                                className="rv-sec-feature"
                                key={index}
                                >
                                    {`*${value}`}
                                </div>
                                )
                            }
                    </div>
                    {
                        reserve&&
                        <button 
                        className="reserveBtn"
                        onClick={()=>reserve()}
                        >
                            立即預定
                        </button>
                    }
                </div>
                <div 
                className="rv-close"
                onClick={()=>close()}
                >
                    ✖
                </div>
            </div>
        </div>
    )
}
export default RoomView