import { useContext, useRef } from "react";
import { createContext, useReducer } from "react"
import { AuthContext } from "./AuthContext";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";

function getOrder () {
    const prevOrder = localStorage.getItem('order')
    if(prevOrder){
        const formatOrder = JSON.parse(prevOrder)
        return formatOrder
    }else{
        return null
    }
}
const INITIAL_STATE = 
// getOrder()||
{
    orderer:null,
    mobile:null,
    address:null,
    email:null,
    night:null,
    startDate: null,
    endDate: null,
    orderType:null,
    roomNumber:[],
    addPersonRoomNumber:[],
    roomPerson:0,
    totalPerson:0,
    veganType:'none',
    veganNumber:0,
    discountId:undefined,
    roomPrice:0,
    totalPrice:0,
    addPersonPrice:0,
    discountPrice:0,
    otherRequest:[],
    requiredDepositAmount:0,
    //
    room:[],
    hasAgreedTheRule:false
}
export const OrderContext = createContext(INITIAL_STATE);
const OrderReducer = (state,action) => {
    switch(action.type){
        case 'SET_ORDER':
            return {
                ...state,
                ...action.payload
                }
        case 'RESET_ORDER':
            return {
                ...INITIAL_STATE,
                ...action.payload
                }
        default: break;
    }
}
export const OrderContextProvider = ({children}) => {
    axios.defaults.withCredentials = true
    const [state,dispatch] = useReducer(OrderReducer,INITIAL_STATE)
    const {
        'user':authUser
    } = useContext(AuthContext)
    // const [isInitialized,setIsInitialized] = useState(false)
    const isInitialized = useRef(false)
    // fetch data
    const [calendar,setCalendar] = useState([])
    const [rooms,setRooms] = useState([])
    const [deposit,setDeposit] = useState()
    const [user,setUser] = useState()
    const [discounts,setDiscounts] = useState([])
    const [rule,setRule] = useState('')
    const fetchUserData = async (userId) => {
        try {
            axios.defaults.withCredentials = true
            let userData
            let discountData = []
            if(userId){
                userData = await axios.get(
                    process.env.REACT_APP_API_ADDRESS + `/api/user/${userId}`
                    ).then((v)=>v.data[0])
                if(userData.discountIds.length > 0){
                    discountData = await axios.get(
                            process.env.REACT_APP_API_ADDRESS + '/api/info/discount_by_discountids',
                            {'params':{'discountids':userData.discountIds}}
                            ).then((v)=>v.data.filter(({expiryDate})=>new Date(expiryDate).getTime()>=new Date().getTime()))
                }
            }
            return {
                userData,
                discountData
            }
            // setUser(userData)
            // setDiscounts(discountsData)
            // dispatch({
            //     'type':'SET_ORDER',
            //     'payload':{
            //         'orderer':userData?.username,
            //         'mobile':userData?.mobile,
            //         'address':userData?.address
            //     }
            // })
        } catch (error) {
                console.log(error)
        }
    }
    // const fetchAndSetUser = async (userId) => {
    //     try {
    //         axios.defaults.withCredentials = true
    //         let userData
    //         let discountsData = []
    //         if(userId){
    //             userData = await axios.get(
    //                 process.env.REACT_APP_API_ADDRESS + `/api/user/${userId}`
    //                 ).then((v)=>v.data[0])
    //             if(userData.discountIds.length > 0){
    //                 discountsData = await axios.get(
    //                         process.env.REACT_APP_API_ADDRESS + '/api/info/discount_by_discountids',
    //                         {'params':{'discountids':userData.discountIds}}
    //                         ).then((v)=>v.data.filter(({expiryDate})=>new Date(expiryDate).getTime()>=new Date().getTime()))
    //             }
    //         }
    //         setUser(userData)
    //         setDiscounts(discountsData)
    //         dispatch({
    //             'type':'SET_ORDER',
    //             'payload':{
    //                 'orderer':userData?.username,
    //                 'mobile':userData?.mobile,
    //                 'address':userData?.address
    //             }
    //         })
    //     } catch (error) {
    //             console.log(error)
    //     }
    // }
    const reset = async () => {
        await axios.get(process.env.REACT_APP_API_ADDRESS + '/api/info/calendar').then((v)=>setCalendar(v.data))
        await axios.get(process.env.REACT_APP_API_ADDRESS + '/api/info/room').then((v)=>setRooms(v.data))
        await axios.get(process.env.REACT_APP_API_ADDRESS + '/api/info/deposit_info').then((v)=>setDeposit(v.data))
        await axios.get(process.env.REACT_APP_API_ADDRESS + '/api/info/rule').then((v)=>setRule(v.data))
        let payload = {}
        if(authUser){
           const {userData, discountData} = await fetchUserData(authUser?.userId)
            setUser(userData)
            setDiscounts(discountData)
            payload.orderer = userData?.username
            payload.mobile = userData?.mobile
            payload.address = userData?.address
            payload.email = userData?.email
        }
        // const prevOrder = getOrder()
        // if(prevOrder){
        //     payload = {
        //         ...prevOrder,
        //         ...payload
        //     }
        // }
        dispatch({
            type:'SET_ORDER',
            payload
        })
    }
    const initialize = () => {
        if(!isInitialized.current){
            reset()
            isInitialized.current = true
        }
    }
    // initialize()
    useEffect(()=>{
        initialize()
    },[])
    return (
        <OrderContext.Provider
        value={
            {
                'order':state,
                'userData':user,
                'discounts':discounts,
                'deposit':deposit,
                'rooms':rooms,
                'calendar':calendar,
                'rule':rule,
                reset,
                // fetchAndSetUser,
                dispatch,
                isInitialized
            }
        }
        >
            {children}
        </OrderContext.Provider>
    )
}