import './loginAndRegister.scss'
import { useNavigate } from 'react-router-dom'
import React, { useContext, useState } from 'react'
import { AuthContext } from '../../context/AuthContext'
import axios from 'axios'
import useField from '../../hook/useField'
import GoogleAuth from '../googleAuth/GoogleAuth'
import LineAuth from '../lineAuth/LineAuth'

export const login = async (req,dispatch) => {
  axios.defaults.withCredentials=true
  try {
    const res = await axios.post(
      process.env.REACT_APP_API_ADDRESS + '/api/auth/login',
      req
      )
    dispatch({
        type:"LOGIN_SUCCESS",
        payload:res.data
      })
    return {success:true}
  } catch (error) {
    console.log(error)
    dispatch({
      type:"LOGIN_FAILURE",
      payload:error
    })
    throw new Error('登入失敗')
  }
}
const LoginAndRegister = ({callback,goTo}) => {
    axios.defaults.withCredentials = true
    const navigate = useNavigate()
    // const [show,setShow] = useState('login')
    // const defaultLoginInfo = [
    //   {'key':'mobile','value':'','error':null},
    //   {'key':'password','value':'','error':null}
    // ]
    // const defaultRegisterInfo = [
    //   {'key':'username','value':'','error':null},
    //   {'key':'email','value':'','error':null},
    //   {'key':'address','value':'','error':null},
    //   {'key':'mobile','value':'','error':null},
    //   {'key':'password','value':'','error':null},
    //   {'key':'checkPassword','value':'','error':null}
    // ]
    // const defaultRegisterGoogleInfo = [
    //   {'key':'username','value':'','error':null},
    //   {'key':'address','value':'','error':null},
    //   {'key':'mobile','value':'','error':null}
    // ]
    // const [loginInfo,setLoginInfo] = useState(defaultLoginInfo.concat())
    // const [registerInfo,setRegisterInfo] = useState([])
    // const [googleCred, setGoogleCred] = useState(null)
    const [showPolicy,setShowPolicy] = useState(true)
    // const {
    //   getUserField
    // } = useField()
    // const getField = (key) => {
    //   if(key==='checkPassword'){
    //     return {
    //       'alias':'確認密碼',
    //       'type':'password',
    //       'placeholder':'請再次確認密碼'
    //     }
    //   }
    //   return getUserField(key)
    // }
    const {user,dispatch} = useContext(AuthContext) 
    // const insertInfo = (info,key,prop,value) => {
    //   return info.map((i)=>{
    //                     if(i.key===key){
    //                       i[prop]=value
    //                     }
    //                     return i
    //                   })
    // }
    // const validate = async (page) => {
    //   let newFields
    //   let setInfo
    //   switch(page){
    //     case 'login':
    //       newFields = [...loginInfo]
    //       setInfo = setLoginInfo
    //       break
    //     case 'register':
    //       newFields = [...registerInfo]
    //       setInfo = setRegisterInfo
    //       break
    //     default:return
    //   }
    //   for(const field of newFields){
    //     const {
    //       key,
    //       value,
    //     } = field
    //     const regex = getField(key)?.regex || null
    //     field.error = null
    //     if(
    //       value.length<=0||
    //       !value
    //     ){
    //       field.error = '不得空白'
    //     }else if(
    //       regex&&
    //       !value.match(regex)
    //     ){
    //       field.error = '格式錯誤'
    //     }else if(
    //       key==='checkPassword'&&
    //       registerInfo.find((i)=>i.key==='password').value!==value
    //     ){
    //       field.error = '密碼有誤'
    //     }
    //   }
    //   if(newFields.some(({error})=>error)){
    //     setInfo(newFields)
    //     throw new Error('輸入有誤')
    //   }
    // }
    // const checkDuplication = async (req) => {
    //   try {
    //     const res = await axios.get(
    //       process.env.REACT_APP_API_ADDRESS + '/api/info/user_dup/',
    //       {'params':{...req,'inclusive':true}}
    //       ).then(({data})=>data>=1)
    //     return res
    //   } catch (error) {
    //     throw new Error('連線錯誤')
    //   }
    // }
    // const login = async (req,dispatch) => {
    //   try {
    //     const res = await axios.post(
    //       process.env.REACT_APP_API_ADDRESS + '/api/auth/login',
    //       req
    //       )
    //     const verified = req?.credential && res.data?.verified
    //     if(verified){
    //       return {success:false,verified:true}
    //     }
    //     dispatch({
    //         type:"LOGIN_SUCCESS",
    //         payload:res.data
    //       })
    //     return {success:true}
    //   } catch (error) {
    //     dispatch({
    //       type:"LOGIN_FAILURE",
    //       payload:error
    //     })
    //     throw new Error('登入失敗')
    //   }
    // }
    // const register = async (req) => {
    //   try {
    //     await axios.post(
    //       process.env.REACT_APP_API_ADDRESS + '/api/auth/register',
    //       req
    //       )
    //   } catch (error) {
    //     throw new Error('註冊失敗')
    //   }
    // }
    // const handleLogin = async() =>{
    //   try {
    //     const loginReq = loginInfo.reduce((acc,cur)=>({...acc,[cur.key]:cur.value}),{})
    //     const hasUser = await checkDuplication({'mobile':loginReq.mobile})
    //     await validate('login')
    //     if(!hasUser){
    //       setLoginInfo((prev)=>insertInfo(prev,'mobile','error','尚未註冊'))
    //       return
    //     }
    //     //maybe add check password
    //     await login(loginReq)
    //     if(callback){
    //       await callback()
    //     }
    //     if(goTo){
    //       navigate(goTo)
    //     }
    //   } catch (error) {
    //       setLoginInfo((prev)=>insertInfo(prev,'password','error','密碼錯誤'))
    //   }
    //   }
    // const handleRegister = async () => {
    //   try {
    //     //format check
    //     await validate('register')
    //     //make req
    //     const credReq = googleCred?{'credential':googleCred}:null
    //     let registerReq = {
    //                         ...registerInfo.reduce((acc,cur)=>({...acc,[cur.key]:cur.value}),{}),
    //                         ...credReq
    //                       }
    //     let loginReq =  credReq||
    //                     {
    //                       'mobile':registerReq.mobile,
    //                       'password':registerReq.passwor
    //                     }
    //     let newInfo = [...registerInfo]
    //     //chekc dup
    //     const checkList = [
    //       'mobile',
    //       'email',
    //       'nationalId'
    //     ]
    //     for(const key of checkList){
    //       if(registerReq?.[key]){
    //         const dup = await checkDuplication({[key]:registerReq[key]})
    //         if(dup){
    //           newInfo = insertInfo(newInfo,key,'error','已經註冊')
    //         }else{
    //           newInfo = insertInfo(newInfo,key,'error',null)
    //         }
    //       }
    //     }
    //     if(newInfo.some(({error})=>error)){
    //       setRegisterInfo((prev)=>newInfo)
    //       return
    //     }
    //     // start
    //     await register(registerReq)
    //     await login(loginReq)
    //     if(callback){
    //       await callback()
    //     }
    //     if(goTo){
    //       navigate(goTo)
    //     }
    //   } catch (error) {
    //     console.log(error)
    //   }
    // }
    // const handleInput = (e) => {
    //   e.preventDefault()
    //   const [page,field] = e.target.id.split('-')
    //   let setInfo
    //   if(page==='login'){
    //     setInfo = setLoginInfo
    //   }else if(page==='register'){
    //     setInfo = setRegisterInfo
    //   }
    //   setInfo((prev)=>insertInfo(prev,field,'value',e.target.value))
    // }
    const handleGoogle = async (response) => {
        const res = await login({method:'google',credential: response.credential},dispatch)
        // if(res.success){
        //   //login successfully
        //   // if(callback){
        //   //   await callback()
        //   // }
        //   if(goTo){
        //     navigate(goTo)
        //   }
        // }
        // else if(res?.verified){
        //   // verified cred but should register
        //   setGoogleCred(response.credential)
        //   setLoginInfo(defaultLoginInfo.concat())
        //   setRegisterInfo(defaultRegisterGoogleInfo.concat())
        //   setShow('register')
        // }
    }
    React.useEffect(()=>{
      if(user){
        navigate(goTo)
      }
    },[user])
  return (
    <div className="login-register-container">
      {showPolicy&&
        <div 
        className="lr-login-policy-container"
        >
          <div className="lr-login-policy-content">
          請注意，您透過 LINE 或 GOOGLE 帳號登入，本方會紀錄您的 E-Mail 做為必要的登入資訊，請按"同意"後繼續。
          <div className="lr-login-policy-btns">
          <button 
          className='btn agree'
          onClick={()=>setShowPolicy(false)}
          >
            同意
          </button>
          <button 
          className='btn disagree'
          onClick={()=>navigate('/')}
          >
            不同意
          </button>
          </div>
          </div>
        </div>
        }
        <div 
        className='lr-page'
        >
          {/* <div className="lr-form">{
            loginInfo.map(({
              key,
              value,
              error
            })=>{
              const field = getField(key)
              const id = ['login',key].join('-')
              const placeholder = field?.placeholder || `請輸入${field.alias}...`
              return(
                <div 
                className="lr-field"
                key={id}
                > 
                  <label 
                  className='lr-fld-title'
                  htmlFor={id}
                  >
                    {field.alias}
                  </label>
                  <div className="lr-fld-right">
                    <input 
                      id={id}
                      type={field?.type||'text'}
                      value={value}
                      placeholder={placeholder} 
                      onChange={(e)=>handleInput(e)}
                      />
                    {
                    error&&
                      <div className="lr-fld-error">{`*${error}`}</div>
                    }
                  </div>
                </div>
              )
            })
          }
          </div> */}
          <div className="lr-buttons">
            {/* <button 
              onClick={()=>handleLogin()}
            >
              一般登入
            </button> */}
            <LineAuth
            className='lr-button'
            callbackUri={process.env.REACT_APP_LINE_CALLBACK_URL}
            state={{redirect:goTo,}}
            />
            <GoogleAuth 
            className="lr-button" 
            handleCredentialResponse={handleGoogle}/>
            {/* <button 
              onClick={()=>{
                setLoginInfo(()=>defaultLoginInfo.concat())
                setRegisterInfo(defaultRegisterInfo.concat())
                setShow('register')
              }}
            >
              我要註冊
            </button> */}
          </div>
        </div>
      {/* <div className={`lr-page ${show==='register'&&'show'}`}>
        <div className="lr-form">
        {
          registerInfo.map(({
            key,
            value,
            error
          })=>{
            const field = getField(key)
            const id = ['register',key].join('-')
            const placeholder = field?.placeholder || `請輸入${field.alias}...`
            return(
              <div 
              className="lr-field"
              key={id}
              > 
                <label 
                className='lr-fld-title'
                htmlFor={id}
                >{field.alias}
                </label>
                <div className="lr-fld-right">
                  <input 
                    id={id}
                    type={field?.type||'text'}
                    value={value}
                    placeholder={placeholder} 
                    onChange={(e)=>handleInput(e)}
                    />
                  {
                  error&&
                    <div className="lr-fld-error">{`*${error}`}</div>
                  }
                </div>
              </div>
            )
          })
        }
        </div>
        <div className="lr-buttons">
          <button 
            onClick={()=>handleRegister('register')}
          >
            {googleCred
            ?'用 GOOGLE 註冊'
            :'立即註冊'
            }
          </button>
          <button 
            onClick={()=>{
              setShow('login')
              setGoogleCred(null)
            }}
          >
            改為登入
          </button>
        </div>
      </div> */}
  </div>
  )
}

export default LoginAndRegister