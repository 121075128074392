import './auth.scss'
import Navbar from '../../components/navbar/Navbar.jsx'
import Footer from '../../components/footer/Footer.jsx'
import LoginAndRegister from '../../components/loginAndRegister/LoginAndRegister'
import Body from '../../components/body/Body'
import { useLocation } from 'react-router-dom'
const Auth = () => {
  const {state} = useLocation()
  return (
    <div className="auth">
      <Navbar/>
      <Body>
        <LoginAndRegister
        goTo={state?.goto||'/user_order'}
        />
      </Body>
      <Footer/>
    </div>
  )
}
export default Auth
