import React from 'react'
import OrderView from '../../components/orderView/OrderView'
import { useNavigate, useParams } from 'react-router-dom'
import useFetch from '../../hook/useFetch'
import Navbar from '../../components/navbar/Navbar'
import Body from '../../components/body/Body'
import './order.scss'
import Footer from '../../components/footer/Footer'

const Order = () => {
    const {id} = useParams()
    const {data,refetch} = useFetch(process.env.REACT_APP_API_ADDRESS+`/api/order/?`,{params:{orders:[id]}})
    const order = data[0]
    const navigate = useNavigate()
  return (
    <div className="order">
        <Navbar/>
        <Body>
        {order?
        <OrderView
            order={order}
            refetch={refetch}
            mode={'normal'}
            close={()=>{
                navigate('/user')
            }}
        />:
        <div className="error">
            訂單無效
        </div>
        }
        </Body>
        <Footer/>
    </div>
  )
}

export default Order