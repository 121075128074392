import axios from "axios";
import { isBefore } from "date-fns";
import { createContext, useEffect, useReducer } from "react"

const INITIAL_STATE = {
    user: getUser(),
    loading: false,
    error: null
};
export const AuthContext = createContext(INITIAL_STATE);
const AuthReducer = (state,action) => {
    switch (action.type) {
        case "LOGIN_START" :
            return {
                user:null,
                loading:true,
                error:null
            };
        case "LOGIN_SUCCESS" :
            return {
                user: action.payload,
                loading: false,
                error: null
            };
        case "LOGIN_FAILURE" :
            return {
                user: null,
                loading: false,
                error: action.payload
            }
            ;
        case "LOGOUT" :
            return {
                user: null,
                loading: false,
                error: null
            };
        default : return state;
    }
};
export const AuthContextProvider = ({children}) => {
    axios.defaults.withCredentials = true
    const [state, dispatch] = useReducer(AuthReducer,{...INITIAL_STATE});
    useEffect(() => {
        const setData = async () => {
            try{
                // const hasUser = Object.keys(state?.user||{}).length>0
                const hasUser = state?.user?true:false
                if(!hasUser){
                    await axios.post(
                        process.env.REACT_APP_API_ADDRESS + '/api/auth/logout'
                        )
                    localStorage.removeItem("user")
                }
                localStorage.setItem("user",JSON.stringify(state.user));
            }catch(err){
                console.log(err)
            }
        }
        setData()
    },[state.user]);
    return(
    <AuthContext.Provider 
    value = {
        {
            'user': state.user,
            'loading': state.loading,
            'error': state.error,
            dispatch
        }
    }
    >
        {children}
    </AuthContext.Provider>
    )
};

function getUser () {
    const user = JSON.parse(localStorage.getItem('user'))
    const isValid = user&&
                    isBefore(
                            new Date(),
                            new Date(
                                user.expires
                                )
                    )
    return isValid?user:null
}