import { useNavigate } from 'react-router-dom'
import * as url from '../../img/logo.webp'
import './homeButton.scss'

const HomeButton = ({onClick}) => {
    const navigate = useNavigate()
    return(
        <div className="home-button">
            <img 
                src={url.default}
                // src="https://i.ibb.co/4NTCKkw/logo.png" 
                alt="logo" 
                onClick={()=>onClick
                            ?onClick()
                            :navigate('/')}
            />
        </div>
    )
}
export default HomeButton