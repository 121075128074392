import React, { useContext,useMemo,useState } from 'react'
import { OrderContext } from '../../context/OrderContext'
import './checkForm.scss'
import Form from '../form/Form'
import useField from '../../hook/useField'
import { infoFormatter } from '../../function/formTools'
import Information from '../information/Information'
import ReserveButton from '../reserveButton/ReserveButton'

const FieldItem = ({objKey,value,error}) =>{
  const {
    getOrderField
  } = useField()
  const field = {key:objKey,...getOrderField(objKey)}
  return(
    <div 
    className="field-item" 
    // style={{'gridTemplateColumns': `90px auto`}}
    >
        <div className="fd-title">{
          '*'+field.alias
        }</div>
        <label htmlFor={objKey}>
                <input 
                    className="fd-input"
                    id={'check-form-input-'+objKey}
                    type='text'
                    autoComplete="off"
                    defaultValue={value}
                    // onChange={(e)=>handleInput(e,key,e.target.value)}
                    />
        </label>
        {error&&
        <div className="fd-error">{error}</div>
        }
    </div>
  )
}
const CheckForm = ({setTab,handleCreateOrder}) => {
  const {
    order,
    rule,
    dispatch
  } = useContext(OrderContext)
  // const {getOrderField} = useField()
  // const [isEditing,setIsEditing] = useState(false)
  const hasAgreed = order.hasAgreedTheRule
  // const hasInfo = (order.orderer&&order.mobile&&order.address)?true:false
  // const [error,setError] = useState(null)
  const [errors,setErrors] = useState({})
  // const hasError = useMemo(()=>Object.values({...errors}).flat().length,[errors])
  // function checkFullfilled(_isEditing,_hasAgreed,_order){
  //   const isFullfilled = 
  //                         (_isEditing===false)&&
  //                         _order.orderer&&
  //                         _order.mobile&&
  //                         _order.address&&
  //                         (_hasAgreed===true)&&
  //                         (rule.length>1)
  //   setSessions(prev=>{
  //           let newSess = [...prev]
  //           newSess[2].isFullfilled = isFullfilled
  //           return newSess
  //         })
  // }
  // React.useEffect(()=>{
  //   checkFullfilled(isEditing,hasAgreed,order)
  // },[isEditing,hasAgreed,order])
  const validate = () => {
    let hasError
    let update = {}
    for(const key of ['orderer','mobile','address','email']){
      const value = document.getElementById('check-form-input-'+key)?.value
      const isEmpty = value.match(/[\S]+/)?.[0]?false:true
      if(isEmpty){
        setErrors(prev=>({
          ...prev,
          [key]:'不得空白'
        }))
        hasError = true
      }else if(key==='mobile'&&!value.match(/^(((\+|00)886)|0)+[0-9]{9}$/)){
        setErrors(prev=>({
          ...prev,
          [key]:'格式範例:0971821650'
        }))
        hasError = true
      }else if(key==='email'&&!value.match(/^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/)){
        setErrors(prev=>({
          ...prev,
          [key]:'格式範例:fongpension@gmail.com'
        }))
        hasError = true
      }else{
        setErrors(prev=>({
          ...prev,
          [key]:null
        }))
        update[key] = value
      }
    }
    return hasError?null:update
  }
  return (
    <div 
    className='check-form'
    >
      <div className="c-f-section">
      <h3 className='c-f-s-title'>{`聯絡資訊（*必填)`}</h3>
      <div className="field-container">
        <FieldItem
        objKey={'orderer'}
        value={order.orderer}
        error={errors?.orderer}
        />
        <FieldItem
        objKey={'mobile'}
        value={order.mobile}
        error={errors?.mobile}
        />
        <FieldItem
        objKey={'address'}
        value={order.address}
        error={errors?.address}
        />
        <FieldItem
        objKey={'email'}
        value={order.email}
        error={errors?.address}
        />
      </div>
        {/* <Form
          title={'聯絡資訊（必填)'}
          option={1}
          btnAlias={{
            editing:'點此填寫'
          }}
          info={infoFormatter(
            [
            'orderer',
            'mobile',
            'address'
            ],
            order
          )}
          getField={getOrderField}
          updateFn={(updatedInfo)=>{
            // let payload = {
            //   orderer:order.orderer,
            //   mobile:order.mobile,
            //   address:order.address
            // }
            // payload = {
            //   ...payload,
            //   ...updatedInfo
            // }
            // checkFullfilled(false,hasAgreed,payload)
            dispatch(
              {
                'type':'SET_ORDER',
                'payload':{
                  ...updatedInfo,
                  // ...payload
                }
              }
            )
          }}
          getEditingStatus={(status)=>{
            // console.log(status)
            setIsEditing(status)
            // checkFullfilled(!status,hasAgreed,order)
          }}
          /> */}
      </div>
      <div className="c-f-section">
        <h3 className='c-f-s-title'>請閱讀並於最下方「按同意」</h3>
        <div className="agreement">
          {
            rule&&
            rule.map((rule,index)=>
            <Information
              info={rule}
              key={index}
              style={{
                marginTop:index===0?0:'20px'
              }}
            />
            )
          }
          
          <div 
          className="ag-bottom"
          onClick={()=>{
            dispatch({
              'type':'SET_ORDER',
              'payload':{
                'hasAgreedTheRule':!hasAgreed
              }
            })
            // checkFullfilled(isEditing,!hasAgreed,order)
          }}
          >
            <div className="agree">
                我同意以上規定：
                {
                <input 
                type="checkbox" 
                checked={hasAgreed}
                onChange={(e)=>{
                  // e.preventDefault()
                  // e.stopPropagation()
                  // dispatch({
                  // 'type':'SET_ORDER',
                  // 'payload':{
                  //   'hasAgreedTheRule':!hasAgreed
                  // }
                // })
              }}
                />
                }
                {/* {
                hasAgreed
                ?'☑'
                :'☐'
                } */}
            </div>
          </div>
        </div>
      </div>
      <ReserveButton
        nextBtn={
          hasAgreed?
          {
          text:'送出訂單',
          disabled:false,
          onClick:()=>{
            const update = validate()
            if(update){
            handleCreateOrder(update)
            }
          }
          }:
          {
            text:'請同意規定',
            disabled:true,
          }
      }
        lastBtn={{
          onClick:()=>setTab(1)
        }}
      />
    </div>
  )
}
export default CheckForm