import './footer.scss'

const Footer = () => {
  return (
    <div className="footer">
        <div className="footer-container">
            {/* <hr/> */}
            <ul className="footer-info">
                <li className="footer-info-item">聯絡電話: 0971-821-650</li>
                <li className="footer-info-item">民宿地址: 康樂路215巷245號</li>
                <li className="footer-info-item">民宿證號: 1650</li>
            </ul>
            <h5 className="footer-copyright">Copyright © 2023 Fong Pension. All rights reserved</h5>
        </div>
    </div>
  )
}

export default Footer