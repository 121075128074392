import './information.scss'

const Information = ({info,...props}) => {
    function getBoldText (text) {
        if(text.match(/(<bold>)(\W|\w)+(<\/bold>)/)){
            const firstIndex = text.search(/(<bold>)/)+'<bold>'.length
            const lastIndex = text.search(/(<\/bold>)/)
            const before = text.split('<bold>')[0]
            const bold = text.slice(firstIndex,lastIndex)
            const last = getBoldText(text.split('</bold>')[1])
            return [
                before,
                bold
            ].concat(last)
        }else{
            return [text]
        }
    }
    return(
        <div 
        className="information"
        {...props}
        >
            {
                info
                .split(/(\n|<br\/>)/)
                .filter((i)=>i!=='\n'&&i!=='<br/>')
                .map((i,index)=>{
                    let text = getBoldText(i)
                    return(
                        <div 
                        className="line"
                        key={index}
                        >
                            {
                                text.map((i,index)=>
                                <span 
                                className={`${((index+1)%2===0)&&'bold'}`}
                                key={index}
                                >
                                    {
                                        i
                                    }
                                    </span>
                                )
                            }
                        </div>
                    )
                })
            }
        </div>
    )
}
export default Information