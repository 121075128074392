import { useEffect, useRef } from 'react'

const GoogleAuth = ({handleCredentialResponse,...props}) => {
const googleButton = useRef(null);
const loadScript = (src)=> new Promise((resolve,reject)=>{
    if(document.querySelector(`script[src="${src}"]`)){
        return resolve()
    }
    const script = document.createElement('script') 
    script.src = src
    script.onload = ()=>resolve()
    script.onerror = (err)=>reject(err)
    document.body.appendChild(script)
    return resolve()
})
useEffect(() => {
    const src = 'https://accounts.google.com/gsi/client'
    loadScript(src).then(()=>{
        const id = process.env.REACT_APP_GOOGLE_CLIENT_ID
        /*global google*/
        google.accounts.id.initialize({
            client_id: id,
            callback: handleCredentialResponse,
        })
        google.accounts.id.renderButton(
            googleButton.current, 
            { theme: 'outline', size: 'large' } 
        )
    })
    return(()=>{
        const scriptTag = document.querySelector(`script[src="${src}"]`)
        if(scriptTag){
          scriptTag.remove()
        }
      })
// const hasDOM = window?.google?true:false
// if(hasDOM){
//     const id = process.env.REACT_APP_GOOGLE_CLIENT_ID
//     /*global google*/
//     google.accounts.id.initialize({
//         client_id: id,
//         callback: handleCredentialResponse,
//     })
//     google.accounts.id.renderButton(
//         googleButton.current, 
//         { theme: 'outline', size: 'large' } 
//     )
// }
//eslint-disable-next-line react-hooks/exhaustive-deps
}, [])
return (
    <div 
    {...props}
    ref={googleButton}
    />
)
}
export default GoogleAuth