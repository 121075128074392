import './chooseDateAndRoom.scss'
import { addDays,differenceInDays,isBefore} from 'date-fns'
import React, { useState,useRef,useContext,useEffect,useMemo} from 'react'
import { OrderContext } from '../../context/OrderContext'
// import axios from 'axios'
import  Calendar  from '../calendar/Calendar.jsx'
import { formatCurrencyText, formatDate } from '../../function/formTools'
import RoomView from '../roomView/RoomView'
import ProgressiveImage from '../progressiveImage/ProgresiveImage.jsx'
import ReserveButton from '../reserveButton/ReserveButton.jsx'
import { useNavigate } from 'react-router-dom'
const RoomItem = ({room,selected,onClick}) => {
    return(
        <div
        className={`room-item clickable ${selected&&'clicked'}`}
        onClick={()=>onClick()}
        >
            <ProgressiveImage
            placeholderSrc={room.photo[0]?.thumb?.url}
            />
            <div className="room-i-title">
            <div className='room-i-roomNumber'>{`${room.roomNumber}`}</div> 
            <span>{`${room.person}人房`}</span>
            </div>
        </div>
        )
    }
const ChooseDateAndRoom = ({setTab}) => {
    const {
        order,
        calendar,
        rooms,
        deposit,
        dispatch
    } = useContext(OrderContext)
    const hasData =  (calendar.length && rooms.length && deposit)?true:false
    const prestate = getPrestate()
    function getPrestate () {
        return {
            startDate:new URLSearchParams(window.location.search).get('startDate'),
            endDate:new URLSearchParams(window.location.search).get('endDate'),
            roomNumber:new URLSearchParams(window.location.search).getAll('roomNumber[]')
        }
    }
    const {
        firstDate,
        lastDate,
        startDate,
        endDate,
        night,
        'error':default_error,
        'room':default_choosableRoom
    } = useMemo(()=>{
        const firstDate = (calendar.find((i)=>i?.dateType!=='closed'))?.date||
            addDays(new Date(new Date().toLocaleDateString('en-US',{timeZone:'Asia/Taipei'})),1)
        const lastDate = calendar[calendar.length-1]?.date
        const startDate = formatDate(
            prestate.startDate||
            firstDate
            )
        const endDate = formatDate(
            prestate.endDate||
            addDays(startDate,1)
            )
        const newDates = {
            startDate,
            endDate,
            'night':differenceInDays(endDate,startDate)
        }
        const res = hasData
                    ? getChoosableRoom(newDates,calendar)
                    : {
                        'error':'正在連線...',
                        'room':[]
                        }
        return {
            firstDate,
            lastDate,
            startDate,
            endDate,
            night:differenceInDays(endDate,startDate),
            ...res
        }
    //eslint-disable-next-line react-hooks/exhaustive-deps
    },[calendar,rooms,deposit]) 
    const checkin = useRef() //date picker
    const checkout = useRef() //date picker
    const [dates,setDates] = useState({
        'startDate':startDate,
        'endDate':endDate,
        'night':night
    })
    const [choosableRoom,setChoosableRoom]= React.useState(default_choosableRoom)
    const [error,setError] = React.useState(default_error)
    // const [waitingCount,setWaitingCount] = React.useState(null)
    const [openRoomNumber,setOpenRoomNumber] = React.useState(null)
    const [openCalendar,setOpenCalendar] = React.useState(false)
    const [isInitialized,setIsInitialized] = React.useState(false)
    function handleDates (_dates) {
        let newDates =  {
            ...dates,
            ..._dates
            }
        const night = differenceInDays(new Date(newDates.endDate),new Date(newDates.startDate))
        newDates = {
            ...newDates,
            night
        }
        //重置選擇的房間
        // setChoosedRoomNum([])
        //更新可選擇房間或錯誤訊息
        const result = getChoosableRoom(newDates,calendar)
        if(result.error){
            setError(result.error)
            setChoosableRoom(null)
        }else{
            setError(null)
            setChoosableRoom(result.room)
            dispatch(
                {
                    type:'SET_ORDER',
                    payload:{
                        ...newDates,
                        'room':[],
                        'roomNumber':[],
                        'addPersonRoomNumber':[],
                        'orderType':null,
                        'roomPrice':null,
                        'totalPrice':null
                    }
                })
        }
        // setWaitingCount(null)
        setDates(newDates)
        //sesssion[0] unfullfilled
        // prev=>{
        //     let newSess = [...prev]
        //     for(let sess of newSess){
        //         if([0].includes(sess.session)){
        //             sess.isFullfilled = false
        //         }
        //     }
        //     return newSess
        // })
    }
    function handleChooseRoomNum (prev_choosedRoomNum,roomNumber) {
        let updatedRoomNum = prev_choosedRoomNum.includes(roomNumber)
                            ?prev_choosedRoomNum.filter((prevRoomNum)=>prevRoomNum!==roomNumber)
                            :[...prev_choosedRoomNum,roomNumber].sort((a,b)=>a-b)
        //更新訂單，成功回傳true
        const {isSessionFullfilled,orderType} = updateOrder(updatedRoomNum,choosableRoom)
        //如果是候補訂單，取得waitingCount
        if(orderType==='waiting'){
            // handleWaitingCount(updatedRoomNum)
        }else if(orderType==='availible'){
            // setWaitingCount(null)
        }
        //更新Session[0] isFullfilled
        // prev=>{
        //     let newSess = [...prev]
        //     for(let sess of newSess){
        //         if([0,1].includes(sess.session)){
        //             sess.isFullfilled = isSessionFullfilled
        //         }
        //     }
        //     return newSess
        // })
    }  
    function updateOrder (choosedRoomNum,choosableRoom) {
        //決定訂單種類 availible 或 waiting
        const choosedRoom = choosedRoomNum.reduce((acc,cur)=>{
            const room = choosableRoom.find((item)=>item.roomNumber===cur)
            acc.room.push(room)
            if(acc.orderType!=='waiting'){
                acc.orderType = room?.status
                return acc
            }else{
                return acc
            }
        },{
            'orderType':null,
            'room':[]
        })
        //設置 session
        const isSessionFullfilled = choosedRoom.orderType?true:false
        //若無選擇，重置訂單
        if(!choosableRoom.length){
            dispatch(
                {
                    type:'RESET_ORDER',
                    payload:{
                        ...dates
                    }
                }
            )
        }else{
            let _totalPrice = choosedRoom.room.reduce((acc,cur)=>acc+cur.totalPrice,0)
            const person = getPerson(rooms,choosedRoomNum)
            dispatch(
                {
                    type:'SET_ORDER',
                    payload:{
                        ...dates,
                        'orderType':choosedRoom.orderType,
                        'room':choosedRoom.room,
                        'roomNumber':choosedRoomNum,
                        'addPersonRoomNumber':[],
                        'roomPerson':person,
                        'totalPerson':person,
                        'roomPrice':_totalPrice,
                        'totalPrice':_totalPrice,
                        'requiredDepositAmount':Math.ceil(_totalPrice*deposit.depositRate/100)*100
                    }
                }
            )
        }
        return {isSessionFullfilled,'orderType':choosedRoom.orderType}
    }
    function getChoosableRoom(dates,calendar){
        const start = new Date(dates.startDate).getTime()
        const end = new Date(dates.endDate).getTime()
        const firstDate = new Date(calendar[0]?.date).getTime()
        const lastDate =  new Date(calendar[calendar.length-1]?.date).getTime()
        if(
            start>=end
            ||start<firstDate
            ||end>lastDate){
            return({error:"請重新選擇日期",room:[]})
        }else if(dates.night>5){
            return({error:'最多入住5晚',room:[]})
        }
        const choosableRoom = function () {
            const dateRange = calendar.filter((item)=>{
                return (item.date >= start) && (item.date < end)
            })
            //不連續
            if(dateRange.length!==((end-start)/(1*24*60*60*1000))){return null}
            let _choosableRoom = {}
            for(const item of dateRange){
                if(item.dateType==='closed'){return null}
                //每個日期的房間細節
                for(const room of item.room){
                    const roomNumber = room.roomNumber
                    const detail = {
                        'date':item.date,
                        'roomPrice':room.roomPrice,
                        'addPersonPrice':room.addPersonPrice,
                        'canAddPerson':room.canAddPerson
                    }
                    const status = function(){
                        if(room.isClosed){
                            return 'closed'
                        }else if(room.hasOrder){
                            return 'waiting'
                        }else{
                            return 'availible'
                        }
                    }()
                    const roomPerson = getPerson(rooms,[roomNumber])
                    const canAddPerson = room.canAddPerson
                    //初始化
                    if(!_choosableRoom.hasOwnProperty(roomNumber)){
                        _choosableRoom[roomNumber] = room.isClosed 
                        ?   {roomNumber,status}
                        :   {
                                roomNumber,
                                status,
                                roomPerson,
                                'detail':[],
                                'totalPrice':0,
                                'totalAddPersonPrice':0,
                                'canAddPerson':true
                            }
                    }
                    //該房號期間沒有關閉的話，繼續完成細節
                    if(_choosableRoom[roomNumber].status!=='closed'){
                        //只有avalible訂單能被waiting覆寫
                        if(_choosableRoom[roomNumber].status === "availible"){
                            _choosableRoom[roomNumber].status = status
                        }
                        //一但有false，canAddPerson就不再是true
                        if(!canAddPerson){
                            _choosableRoom[roomNumber].canAddPerson = false
                        }
                        //
                        _choosableRoom[roomNumber].detail.push(detail)
                        _choosableRoom[roomNumber].totalPrice += room.price
                        _choosableRoom[roomNumber].totalAddPersonPrice += room.addPersonPrice
                    }
                }
            }
            return  Object.values(_choosableRoom)
                    .filter((room)=>!(['closed','waiting'].some((status)=>status===room.status)))
                    .sort((a,b)=>a.roomNumber-b.roomNumber)
        }()
        if(!choosableRoom){
            return({error:'無可預訂房間',room:[]})
        }else{
            return({error:null,room:choosableRoom})
        }
    }
    function getPerson(rooms,choosedRoomNum){
        const roomScheme = rooms.reduce((acc,cur)=>{
                                acc[cur.roomNumber] = cur
                                return acc
                            },{})
        if(!roomScheme
            && !choosedRoomNum){
                return 0
            }
        if(choosedRoomNum.length <= 0){
            return 0
        }
        let person = 0
        for(const num of choosedRoomNum){
            person = person + roomScheme[num].person
        }
        return person
    }
    // async function handleWaitingCount(roomNumber){
    //     try {
    //         const res = await axios.get(
    //             process.env.REACT_APP_API_ADDRESS + `/api/info/waiting_count`,
    //             {
    //                 params: {
    //                 'startDate':order.startDate.getTime(),
    //                 'endDate':order.endDate.getTime(),
    //                 roomNumber
    //                 }
    //             }
    //         )
    //         setWaitingCount(res.data)
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }
    useEffect(()=>{
    if(hasData){
        if(!isInitialized){
            const result = getChoosableRoom(dates,calendar)
            if(result.error){
                setError(result.error)
                setChoosableRoom(null)
            }else{
                setError(null)
                setChoosableRoom(result.room)
            }
            //更新訂單，成功回傳true
            const {isSessionFullfilled} = updateOrder(prestate.roomNumber,result.room)
            //更新Session[0] isFullfilled
            // prev=>{
            //     let newSess = [...prev]
            //     for(let sess of newSess){
            //         if([0,1].includes(sess.session)){
            //             sess.isFullfilled = isSessionFullfilled
            //         }
            //     }
            //     return newSess
            // })
            setIsInitialized(true)
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[calendar,rooms,deposit,order])
  return (
    <div className="choose-date-room">
        <div className="c-d-r-layer-1">
            <button
            className='c-d-r-switch-button'
            onClick={()=>setOpenCalendar(i=>!i)}
            >
               {
               !openCalendar?
               '點此 查看日曆':
               '回到 選擇房間'
                }
            </button>
            {openCalendar?
            <div className="c-d-r-top">
                <Calendar
                calendar={calendar}
                setOpenRoomNumber={setOpenRoomNumber}
                />
            </div>:
            <>
            <div className="c-d-r-bottom">
                <div className="c-d-r-choose-date">
                        <div className="c-d-r-choose-date-items">
                            <div className="choose-date-item">
                            <span>入住日期</span>
                                {/* <button
                                onClick={e=>{
                                    e.preventDefault()
                                    checkin.current.showPicker()
                                }}> */}
                                <input 
                                ref={checkin}
                                id="startDate" 
                                type="date" 
                                value={formatDate(dates.startDate,'string')}
                                onClick={e=>{
                                    e.preventDefault()
                                    checkin.current.showPicker()
                                }}
                                onChange={(e)=>{
                                    const value = new Date(e.target.value+'T00:00:00.000+0800')
                                    handleDates(
                                        {
                                        startDate:value,
                                        ...(
                                            isBefore(dates.startDate,dates.endDate)?
                                            {endDate:addDays(value,1)}:
                                            {}
                                            )
                                        }
                                    )
                                }}
                                min={firstDate?formatDate(firstDate,'string'):undefined}
                                max={lastDate?formatDate(lastDate,'string'):undefined}
                                />
                                {/* {
                                formatDate(dates.startDate,'string')
                                } */}
                                {/* </button> */}
                            </div>
                            <div className="choose-date-item">
                            <span>退房日期</span>
                            {/* <button
                                onClick={e=>{
                                e.preventDefault()
                                checkout.current.showPicker()
                                }}> */}

                                <input 
                                ref={checkout}
                                id="endDate" 
                                type="date"
                                onClick={e=>{
                                    e.preventDefault()
                                    checkout.current.showPicker()
                                }}
                                value={formatDate(dates.endDate,'string')}
                                min={
                                    formatDate(addDays(dates.startDate,1),'string')
                                    }
                                max={
                                    formatDate(addDays(dates.startDate,5),'string')
                                    }
                                onChange={(e)=>{
                                    handleDates({
                                        [e.target.id]:new Date(e.target.value+'T00:00:00.000+0800')
                                    })
                                }}
                                />
                                {/* {
                                    isBefore(dates.startDate,dates.endDate)
                                    ? formatDate(dates.endDate,'string')
                                    : '-請選擇日期-'
                                } */}
                                {/* </button> */}
                            </div>
                        </div>
                </div>
                <div className="c-d-r-choose-room">
                    {/* <div className="c-d-r-title">選擇房間</div> */}
                        {   
                            !error
                            && <div className='c-d-r-choosable-room'>
                                {
                                    choosableRoom.map((room)=>{
                                        const roomStatus = room.status
                                        const roomNumber = room.roomNumber
                                        const info = rooms.find(i=>i.roomNumber===roomNumber)
                                        if(roomStatus==='closed'){
                                            return null
                                        }else{
                                        const isClicked = order.roomNumber.includes(roomNumber)
                                        return(
                                        <RoomItem
                                            key={`${roomStatus}-${roomNumber}`}
                                            room={info}
                                            selected={isClicked}
                                            onClick={()=>handleChooseRoomNum(order.roomNumber,roomNumber)}
                                            // classN={`room-item clickable ${isClicked&&'clicked'} ${roomStatus}`}
                                        />
                                        // {roomNumber}
                                        // </RoomItem>
                                        )
                                        }
                                    })
                                }
                            </div>
                        }
                </div>
                <div className="c-d-r-output">
                    {
                    (error || !choosableRoom)
                        ? <div className='error'>{error}</div>
                        : <div>
                            {
                            order.orderType
                            ?   `共 ${formatCurrencyText(order.totalPrice)} 元`
                                // +(
                                //     waitingCount
                                //     ?`，至少等候 ${waitingCount} 組`
                                //     :''
                                // )
                            :`請選擇房間`
                            }
                            </div>
                    }
                </div>
            </div>
            <ReserveButton
            nextBtn={
                (
                    order.startDate&&
                    order.endDate&&
                    order.roomNumber.length
                )?
                {
                    text:'可以預訂，下一步',
                    disabled:false,
                    onClick:()=>{
                            setTab(1)
                    }  
                }:
                {
                    text:'請選擇日期／房型',
                    disabled:true,
                }
            }
            />
            </>
            }
        </div>
        {
            rooms&&
            openRoomNumber&&
            <div className="c-d-r-layer-2">
                <RoomView
                room={rooms.find(({roomNumber})=>roomNumber===openRoomNumber)}
                close={()=>setOpenRoomNumber(null)}
                />
            </div>

        }
    </div>
  )
}
export default ChooseDateAndRoom