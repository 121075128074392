import "./orderView.scss"
import Form from '../form/Form.jsx'
import useField from "../../hook/useField"
import { useContext, useState } from "react"
import { UserContext } from "../../context/UserContext"
import axios from "axios"
import { getOrderStatus } from "../../function/getOrderStatus"
import useFetch from "../../hook/useFetch.js"
// import { addDays, isBefore, isEqual } from "date-fns"

const CustomBtn = ({tool}) => {
    const [loading,setLoading] = useState(false)
    return(
        <div 
        className="custom-form-btn"
        onClick={async()=>{
            setLoading(true)
            await tool.handleUpdate()
            setLoading(false)
        }
        }
        >
            {
            loading?'傳送中...':'送出'
            }
        </div>
    )
}

const OrderView = ({order,refetch,close,mode}) => {
    axios.defaults.withCredentials = true
    // const {
    //     fetchData,
    //     order,
    //     waitingOrder,
    //     completedOrder
    // } = useContext(UserContext)
    const [openConfirmBtn,setOpenConfirmBtn] = useState(false)
    // const openOrder = [
    //                     order,
    //                     waitingOrder,
    //                     completedOrder
    //                 ].flat().find(({_id})=>_id===orderId)
    // const {data,refetch} = useFetch(process.env.REACT_APP_API_ADDRESS+`/api/order/?orders=[${orderId}]`)
    const openOrder = order
    const {
        otherRequest,
        discountId,
        addPersonRoomNumber,
        orderType,
        _id:orderId
    } = openOrder || {}
    // const startDate = new Date(openOrder.startDate)
    // const endDate = new Date(openOrder.endDate)
    const {
        status,
        'text':statusText
    } = getOrderStatus(openOrder)
    const infoFormatter = (fields,info) => {
        return fields.map((key)=>({
                                    'key':key,
                                    'value':info?.[key]||null
                                }))
    }
    const depositOrderInfo = infoFormatter([
        'requiredDepositAmount',
        'depositBankAccount'
    ],openOrder)
    const depositClientInfo = infoFormatter([
        'actualDepositAmount',
        'lastFiveDigitsOfBankAccountFromSender'
    ],openOrder)
    const editableOrderInfo = infoFormatter([
        'orderer',
        'mobile',
        'address',
        'veganType',
        'veganNumber'
    ],openOrder)
    const displayOrderInfo = infoFormatter([
        '_id',
        'startDate',
        'endDate',
        'night',
        'roomNumber'
    ]
    .concat(
        addPersonRoomNumber.length>0 
        ?['addPersonRoomNumber','addPersonPrice']
        :[]
        )
    .concat(
        discountId 
        ?['discountId','discountPrice']
        :[]
        )
    .concat([
        'roomPrice',
        'totalPrice',
        'actualDepositAmount'
    ])
    ,openOrder)
    const {
        getOrderField
    } = useField()
    const leaveOrderView = (e) => {
        e.preventDefault()
        close()
        // dispatch({
        //     type:'CLOSE_ORDER_VIEW'
        // })
    }
    const handleDeposit = async (updatedInfo,makeError) => {
        const errors = [
        'actualDepositAmount',
        'lastFiveDigitsOfBankAccountFromSender'
        ].reduce((acc,key)=>{
            if(!updatedInfo[key]){
                return acc.concat([{'key':key,'msg':'請填寫'}])
            }
            return acc
        },[])
        if(errors.length>0){
            makeError(errors)
        }
        await axios.put(
            process.env.REACT_APP_API_ADDRESS + `/api/order/${orderId}/deposit`,
            updatedInfo)
        // fetchData()
            refetch()
    }
    return(
        <div className={'orderView '+mode}>
            {mode!=='normal'&&
            <div 
            className="ov-bk"
            onClick={(e)=>leaveOrderView(e)}
            />
            }
            <div className="ov-container-border">
                {mode!=='normal'&&
                <div 
                className="ov-close"
                onClick={()=>close()}
                >
                    ✖
                </div>
                }
                <div className="ov-container">
                        <div className="ov-deposit-section">
                            <div className={`ov-ds-status ${status}`}>
                                <span>
                                    {statusText}
                                </span>
                            </div>
                            {status==='pending'&&
                            <div className="ov-ds-info">
                            <Form
                            title={'匯款資訊'}
                            option={3}
                            info={depositOrderInfo}
                            getField={getOrderField}
                            />
                            <Form
                            title={'訂金資訊'}
                            option={2}
                            info={depositClientInfo}
                            getField={getOrderField}
                            updateFn={handleDeposit}
                            hideBtn={true}
                            >
                                <CustomBtn/>
                            </Form>
                            </div>
                            }
                        </div>
                        <Form
                        title={'住客資料'}
                        option={
                            status==='completed'
                            ?3
                            :1
                        }
                        info={editableOrderInfo}
                        validateInfo={openOrder}
                        getField={getOrderField}
                        uniqueCheckFn={()=>{}}
                        updateFn={async(updatedInfo)=>{
                            await axios.put(
                                process.env.REACT_APP_API_ADDRESS + `/api/order/${orderId}`
                                ,updatedInfo
                                )
                            // fetchData()
                            refetch()
                        }}
                        />
                        <Form
                        title={'訂單內容'}
                        option={3}
                        info={displayOrderInfo}
                        getField={getOrderField}
                        />
                        {
                            orderType==='availible'&&
                            <Form
                            title={'其他需求'}
                            option={4}
                            info={
                                [
                                    {
                                        'key':'request',
                                        'value':null
                                    }
                                ]
                            }
                            getField={()=>{
                                return {
                                    alias:'需求',
                                    type:'textarea'
                                }
                            }}
                            updateFn={async(updatedInfo)=>{
                                await axios.put(
                                    process.env.REACT_APP_API_ADDRESS + `/api/order/${orderId}/add_request`,
                                    updatedInfo
                                    )
                                // fetchData()
                                refetch()
                            }}
                            btnAlias={{
                                'editing':'新增'
                            }}
                            textareaFields={['request']}
                            >
                            <div className="request-container">
                                {
                                otherRequest.map(({
                                    request,
                                    requestStatus,
                                    requestReply,
                                    _id
                                })=>{
                                    const field = getOrderField('otherRequest').children.field
                                    const statusAlias = field.requestStatus.option.find((i)=>i.value===requestStatus).alias
                                    return(
                                        <div 
                                        className="request-item"
                                        key={_id}
                                        >
                                            <div className={`req-top ${requestStatus}`}>
                                                {statusAlias}
                                            </div>
                                            <div className="req-middle">
                                                <div className="req-title">{`${field.request.alias}:`}</div>
                                                <div className="req-container">
                                                    {
                                                        request.split('\n').map((value,key)=>
                                                        <span
                                                        key={`${_id}-${key}`}
                                                        >
                                                            {value}
                                                        </span>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            {
                                                requestReply
                                                &&<div className="req-bottom">
                                                        <div className="req-title">{`${field.requestReply.alias}:`}</div>
                                                        <div className="req-container">
                                                            {
                                                                requestReply.split('\n').map((value,key)=>
                                                                <span
                                                                key={`${_id}-${key}`}
                                                                >
                                                                    {value}
                                                                </span>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                            }
                                            
                                        </div>
                                    )
                                })
                                }
                            </div>
                            </Form>
                        }
                        {/* <AdditionalFunc/> */}
                        {
                        status==='pending'&&
                        <div 
                        className="ov-cancel-order"
                        onClick={()=>setOpenConfirmBtn(true)}
                        >
                            取消訂單
                        </div>
                        }
                </div>
            </div>
            {
            openConfirmBtn&&
            <div className="ov-layer-2">
                <div className="ov-confirm-btn">
                    <span>要取消訂單嗎?</span>
                    <button 
                    className="ov-btn-no"
                    onClick={()=>setOpenConfirmBtn(false)}
                    >
                        離開
                    </button>
                    <button 
                    className="ov-btn-yes"
                    onClick={async()=>{
                        await axios.delete(
                            process.env.REACT_APP_API_ADDRESS + `/api/order/${orderId}`
                            )
                        close()
                        // fetchData()
                        refetch()
                    }}
                    >
                        確定
                    </button>
                </div>
            </div>
            }
        </div>
    )
}
export default OrderView