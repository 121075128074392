const userFields = {
    "username":
                {
                    'alias':'會員姓名'
                },
    "mobile":
                {
                    'alias':'會員手機',
                    'regex':/^(((\+|00)886)|0)+[0-9]{9}$/,
                    'unique':true
                },
    "email":
                {
                    'alias':'會員信箱',
                    'regex':/^[\w-.]+@[\w-.]+$/,
                    'unique':true
                },
    "address":
                {
                    'alias':'聯絡地址'
                },
    "nationalId":
                {
                    'alias':'身分證號',
                    'regex':/^[A-Z]+[1,2]+[0-9]{8}/,
                    'unique':true
                },
    "order":
                {
                    'alias':'當前的訂單'
                },
    "waitingOrder":
                {
                    'alias':'候補的訂單'
                },
    "completedOrder":
                {
                    'alias':'完成的訂單'
                },
    "discountIds":
                {
                    'alias':'折扣券'
                },
    "password":
                {
                    'alias':'會員密碼',
                    'type':'password',
                    'placeholder':'請輸入6-10位的密碼',
                    'regex':/^[\w]{6,10}$/
                },
    "curPassword":
                {
                    'alias':'舊密碼',
                    'type':'password',
                },
    "newPassword":
                {
                    'alias':'新密碼',
                    'type':'password',
                    'placeholder':'請輸入6-10位的密碼',
                    'regex':/^[\w]{6,10}$/
                }
}
const orderfields = {
        "_id":{
            'alias':'訂單編號'
        },
		"startDate": {
            'alias':'入住日期',
            'type':'date'
        },
		"endDate": {
            'alias':'退房日期',
            'type':'date'
        },
		"night":{
            'alias':'入住晚數',
            'type':'number'
        },
		"orderType":{
            'alias':'訂單類別',
            'option':[
                {
                    'value':'availible',
                    'alias':'成立'
                },
                {
                    'value':'waiting',
                    'alias':'候補'
                }
            ]
        },
		"roomNumber":{
            'alias':'預訂房號',
            'children':{}
        },
		"addPersonRoomNumber":{
            'alias':'加人房號',
            'children':{}
        },
		"addPersonPrice":{
            'alias':'加人費用',
            'type':'number',
            'unit':'元'
        },
		"roomPerson":{
            'alias':'基本人數'
        },
		"roomPrice":{
            'alias':'原總房價',
            'type':'number',
            'unit':'元'
        },
        "discountId":{
            'alias':'使用折扣'
        },
		"discountPrice":{
            'alias':'折扣價格',
            'type':'number',
            'unit':'元'
        },
		"totalPrice":{
            'alias':'最終價格',
            'type':'number',
            'unit':'元'
        },
        "totalPerson":{
            'alias':'入住人數'
        },
        "userId":{
            'alias':'訂單用戶'
        },
		"orderer":{
            'alias':'預定姓名'
        },
		"mobile":{
            'alias':'手機號碼',
            'regex':/^(((\+|00)886)|0)+[0-9]{9}$/
        },
		"address":{
            'alias':'聯絡地址'
        },
		"email":{
            'alias':'電子信箱'
        },
		"veganNumber":{
            'alias':'素食人數',
            'type':'number',
            'min':0,
            'max':'totalPerson'
        },
		"veganType":{
            'alias':'素食類別',
            'option':[
                {
                    'value':'ovo-lacto',
                    'alias':'蛋奶素'
                },
                {
                    'value':'vegan',
                    'alias':'全素'
                },
                {
                    'value':'none',
                    'alias':'不忌'
                }
            ]
        },
		"otherRequest":{
            'alias':'其他需求',
            'children':{
                        'field':{
                            "request":{
                            'alias':'需求'
                            },
                            "requestReply":{
                                'alias':'回覆'
                            },
                            "requestStatus":{
                                'alias':'狀態',
                                'option':[
                                    {
                                        'value':'fullyApproved',
                                        'alias':'完全同意'
                                    },
                                    {
                                        'value':'partialyApproved',
                                        'alias':'部分同意'
                                    },
                                    {
                                        'value':'pending',
                                        'alias':'待確認'
                                    },
                                    {
                                        'value':'rejected',
                                        'alias':'拒絕'
                                    }
                                ]
                            },
                            "_v":{
                                'alias':'編號'
                            }
                            }
                        },
        },
		"requiredDepositAmount":{
            'alias':'需匯訂金',
            'type':'number',
            'unit':'元'
        },
		"actualDepositAmount":{
            'alias':'實匯訂金',
            'type':'number',
            'unit':'元'
        },
		"depositBankAccount":{
            'alias':'匯款帳號'
        },
        "lastFiveDigitsOfBankAccountFromSender":{
            'alias':'匯款帳號後5碼',
            'regex':/^\d{5}$/
        },
		"isDepositApproved":{
            'alias':'訂金確認',
            'unit':'元',
            'option':[
                {
                    'value':true,
                    'alias':'已確認'
                },
                {
                    'value':false,
                    'alias':'未確認'
                }
            ]
        },
		"transferDepositBefore":{
            'alias':'匯款截止時間',
            'type':'datetime-local'
        },
		"lastEditAdminId":{
            'alias':'最後編輯管理員'
        },
		"createdAt":{
            'alias':'訂單成立時間'
        },
		"updatedAt":{
            'alias':'訂單更新時間'
        },
}
const useField = () => {
    const getUserField = (field) => {
        return userFields[field]
    }
    const getOrderField = (field) => {
        return orderfields[field]
    }
    return {
        getUserField,
        getOrderField
    }
}
export default useField