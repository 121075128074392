import { useHorizontalScroll } from "../../hook/useHorizontalScroll"
import "./itemList.scss"

const ItemList = ({title,items,children}) => {
    const scrollRef = useHorizontalScroll()
    return(
        <div className="itemList">
            {
            title
            &&<div className="subtitle">
                <div className="title">{title}</div>
            </div>
            }
            <div className="il-frame">
                <div 
                className="il-container"
                ref={scrollRef}
                >
                    {children}
                </div>
            </div>
        </div>
    )
}
export default ItemList